/**
 * @name: 入库记录接口
 * @author: itmobai
 * @date: 2023-07-24 11:42
 * @description：入库记录接口
 * @update: 2023-07-24 11:42
 */

import { get, postW } from "@/request";
import { ICheckIn, ICheckInParam } from "./types";
import { IPageRes } from "@/apis/page";

export const mallCheckinQueryApi = (query: ICheckInParam) => get("/mall/checkin/query", query)

export const mallCheckinExportCheckinApi = (data: ICheckInParam) => postW("/mall/checkin/exportCheckin", data, "blob")
