
/**
 * @name: 入库记录
 * @author: itmobai
 * @date: 2023-07-24 11:40
 * @description：入库记录
 * @update: 2023-07-24 11:40
 */
import { Vue, Component } from "vue-property-decorator"
import { ICrudOption } from "@/types/m-ui-crud";
import { mallCheckinQueryApi, mallCheckinExportCheckinApi } from "@/apis/site/checkIn"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { ICheckIn, ICheckInParam } from "@/apis/site/checkIn/types"
import config from "@/config";
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class checkInPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: ICheckIn[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: ICheckInParam = {
    page: 1,
    limit: 10
  }
  totalCheckIn = 0
  // 表单数据
  modelForm: Partial<ICheckIn> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "入库记录ID",
        prop: "id",
        align: "left",
        width: 180,
        search: true
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        search: true,
        placeholder: "输入商品名称模糊搜索"
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
      },
      {
        label: "入库数量(份)",
        prop: "checkinQty",
        align: "center",
        width: 100,
      },
      {
        label: "入库类型",
        prop: "checkinTypes",
        type: "select",
        search: true,
        dicData: [
          {
            label: "采购入库",
            value: 1
          }
        ]
      },
      {
        label: "关联单号",
        prop: "purchaseSn",
        search: true,
        align: "center",
        overHidden: true
      },
      {
        label: "入库时间",
        prop: "checkinTime",
        search: true,
        type: "daterange",
        align: "center",
        width: 180
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const form: ICheckInParam = deepCopy(this.queryForm);
    if (this.queryForm.checkinTime && this.queryForm.checkinTime.length) {
      form.addStartTime = this.queryForm.checkinTime[0]
      form.addEndTime = this.queryForm.checkinTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.checkinTime
    mallCheckinQueryApi(form).then(e => {
      this.totalCheckIn = e.total;
      this.tableTotal = e.checkinVoCommonPage.total
      this.tableData = e.checkinVoCommonPage.list
      this.tableLoading = false
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: ICheckInParam = deepCopy(this.queryForm);
    if (this.queryForm.checkinTime && this.queryForm.checkinTime.length) {
      form.addStartTime = this.queryForm.checkinTime[0]
      form.addEndTime = this.queryForm.checkinTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.checkinTime
    mallCheckinExportCheckinApi(form).then(e => {
      exportFile(e, '入库记录.xlsx')
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
